'use client';

import { useState } from 'react';
import SearchBar from './SearchBar';
import GameGrid from './GameGrid';

const SearchableGameGrid = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  const handleSearch = (keyword: string, tag: string) => {
    setSearchKeyword(keyword);
    setSelectedTag(tag);
  };

  return (
    <div className="space-y-8"> {/* 增加垂直间距 */}
      <div className="flex justify-center"> {/* 使 SearchBar 居中 */}
        <SearchBar onSearch={handleSearch} />
      </div>
      <GameGrid initialKeyword={searchKeyword} initialTag={selectedTag} />
    </div>
  );
};

export default SearchableGameGrid;