'use client';

import React, { useState, useEffect, useCallback } from 'react';
import GameCard from './GameCard';
import SkeletonGameCard from './SkeletonGameCard';
import ErrorBoundary from '@/components/ErrorBoundary';

interface Game {
  id: number;
  title: string;
  imageUrl: string;
  average_rating: number;
  rating_count: number;
  slug: string;  // 添加 slug 属性
}

interface GameGridProps {
  initialKeyword?: string;
  initialTag?: string;
}

const GameGrid = ({ initialKeyword = '', initialTag = '' }: GameGridProps) => {
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchGames = useCallback(async (keyword: string, tag: string) => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch(`/api/games?keyword=${keyword}&tag=${tag}&_=${Date.now()}`, {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const fetchedGames = await response.json();

      if (Array.isArray(fetchedGames)) {
        setGames(fetchedGames.map(game => ({
          ...game,
          id: game.id || Math.random(),
          title: game.title || 'Untitled Game',
          imageUrl: game.imageUrl || '/default-game-image.png',
          average_rating: parseFloat(game.average_rating) || 0,
          rating_count: parseInt(game.rating_count) || 0
        })));
      } else {
        throw new Error('Invalid data format');
      }
    } catch (error) {
      console.error('Error fetching games:', error);
      setError(error instanceof Error ? error.message : 'Failed to load games');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchGames(initialKeyword, initialTag);
    
    const refreshInterval = setInterval(() => {
      fetchGames(initialKeyword, initialTag);
    }, 300000);

    return () => clearInterval(refreshInterval);
  }, [initialKeyword, initialTag, fetchGames]);

  return (
    <ErrorBoundary fallback={<div className="text-center text-red-500">Something went wrong</div>}>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
        {error && <div className="col-span-full text-center text-red-500">{error}</div>}
        {loading && games.length === 0
          ? Array.from({ length: 10 }).map((_, index) => (
              <SkeletonGameCard key={index} />
            ))
          : games.map((game) => (
              <GameCard
                key={game.id}
                title={game.title}
                imageUrl={game.imageUrl}
                slug={game.slug}  // 传递 slug
              />
            ))}
        {!loading && games.length === 0 && (
          <div className="col-span-full text-center text-gray-500">
            No games found
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default GameGrid;
